import React from "react";
import Seo from "../components/seo";
import Header from "../components/header/Header";
import CommonDoc from "../components/CommonDoc";
import CommonDocProfile from "../components/CommonDocProfile";
import ImgBunkacho from "../images/about-logo-01.png";
import ImgAitLogo from '../images/ait-logo.svg'
// markup
const AboutPage = (props) => {
  return (
    <main>
      <Seo lang={`ja`} title={`このプロジェクトについて`} />

      <div>
        <Header lang={`ja`} />
      </div>

      <CommonDoc>
        <h2>このプロジェクトについて</h2>
        <section>
          <p>
            本ウェブサイトの出発点に、2021年1月から2月にかけてインターネット上で行われたアーティスト・イン・レジデンスプログラム「Liminal
            Space –リミナル ・スペース」がある。
          </p>
          <p>
            アーティストを一定期間招き、創作活動に繋がるリサーチや作品制作そのものを行うアーティスト・イン・レジデンスプログラムは、日本だけでなく世界各地で行われ、アーティストのみならず展覧会を企画するキュレーターをはじめリサーチャー、デザイナーやライターなど、昨今は幅広く芸術文化の創作に携わる人々の移動と一時的な滞在、そして現地での交流を支えてきた。{" "}
          </p><p>
            ところが、2020年のはじめから世界的流行となった新型コロナウイルス感染症を端に、国境（時にはより身近な県境までを含み）を超えた移動に大きな制限が敷かれ、レジデンスプログラムはこれまでと異なる考え方や手法を求められるという局面に立たされたと言って良いだろう。{" "}
        </p><p>
            こうした中、オランダのアイントホーフェンに拠点を置くバルタン・ラボラトリーズとアーツイニシアティヴトウキョウの協働によって企画され、オンラインをリサーチと交流の「場」とするアーティスト・イン・レジデンスプログラム「Liminal
            Space –リミナル
            ・スペース」に、公募により選ばれたアーティストの内田聖良とアーティスト・デザイナーとして活動するテレサ・フェルドマンが参加した。
          </p>

          <p>
            ある地点から別の地点への「中間的な変容の場」と大きく訳されるリミナル・スペースは、レジデンスプログラムそのもののあり方を再考する契機としてだけでなく、世界が同じように大きな変化を経験する中で、これまで露わにされてこなかった日常生活の「ひずみ」を浮き彫りにさせ、これに内田とフェルドマンはそれぞれの感覚を敏感にしながら反応している。プログラムを通した「滞在中」は、日本の家制度や社会の中での女性の役割に関心を寄せ、それをオランダの状況と照らし合わせたりしている。
          </p>

          <p>
            今なおリミナルな状況が続く世界的背景と、このレジデンスプログラムの後、日本とオランダだけでなく、あらゆる場所からパンデミック中の「声」や「経験」を紡ぎたい
            –
            そうした思いから本プロジェクトが立ち上がった。ここではじめに用意されたのは、内田とフェルドマンが参加したレジデンスプログラムで関わりを持った複数の書き手によるテキストだが、これをオンラインでアウトソーシングして、これまで関係を持たなかった新たな書き手にトレースしてもらうことを依頼している。しかも、パソコンやインターネットの普及によって（この文章さえも）テキストファイルによるデジタル化された文章が、リモートワークやソーシャルディスタンシングが叫ばれる今、手触りを感じられる「手書き」とした。
          </p>

          <p>
            場所を問わない究極的なリモートワークとも考えられるこうしたアウトソーシングは、こうしたテキストを日本国内、アメリカやケニア、インド、アルジェリアなど、私たちが今は容易に足を運べない場所にまで連れて行ってくれた。そして数日のうちに、リモートワーカーから自身のコメント付きで依頼が戻ってきた（通常のアウトソーシングでは一歩通行とも想像できる依頼の受発注に比べ、「自らの意見や経験を求められる」という今回の逸脱した依頼に、はじめは戸惑いを覚える人が少なくなかったことも記しておきたい）。
          </p>
          <p>
            先に述べたアーティスト・イン・レジデンスプログラムでは、プログラムごとに滞在の様子を記録し、所感が述べられた冊子を制作してきたが、あらゆる試みが許され、これまで既得した知識を解しながら新たな次の学びに対する姿勢を前向きに許容するとされるリミナル・スペースでは、同様の制作方法から離れ、こうしたワールドワイド・レポートとしてウェブサイトに収録した。
          </p>
          <p>
            内田とフェルドマンが共有したフェミニズムへの関心に留まらず、パンデミック下での小さな、けれど確かな私たちの声と経験を、ここに紹介する。
          </p>
        </section>
        <hr />
        <section>
          <p>
            世界的にあらゆる場面で不安定な状況が続く中でも、レジデンスプログラムの実践からワールドワイド
            レポートプロジェクトの完成まで、さまざまな関係者の皆さまに多大な協力をいただきました。また、ワールドワイド
            レポートプロジェクトでは、快く依頼を引き受け、自身の経験を共有くださった国内外のリモートワーカーの皆さまに、この場を借りてお礼申し上げます。
          </p>
        </section>

        <hr />
        <section>
          <CommonDocProfile>
            <h3>Liminal Space –リミナル ・スペース</h3>
            <p>
              AITとバルタン・ラボラトリーズによるレジデンスプログラム「Liminal Space –リミナル ・スペース」<br/>2021年1月15日〜2月26日
            </p>
            <dl>
              <dt>アーティスト</dt>
              <dd>
                <a
                  href={`https://www.a-i-t.net/friends/seira_uchida/`}
                  target={`_blank`}
                >
                  内田聖良
                </a>
                、
                <a
                  href={`https://www.a-i-t.net/friends/teresa_feldmann/`}
                  target={`_blank`}
                >
                  テレサ・フェルドマン
                </a>
              </dd>
              <dt>協力</dt>
              <dd>
                嶋田 美子、藤井
                光、インテ・グローリッヒ、アニア・モレンダ、リンダ・ココ（エア・ブラバント）
              </dd>
              <dt>共催</dt>
              <dd>バルタン・ラボラトリーズ</dd>
              <dt>主催</dt>
              <dd>NPO法人アーツイニシアティヴトウキョウ[AIT/エイト]</dd>
            </dl>
          </CommonDocProfile>
        </section>
        <section>
          <CommonDocProfile>
            <h3>リミナル・スペース ワールドワイド レポート</h3>

            <dl>
              <dt>寄稿</dt>
              <dd>
                嶋田 美子、インテ・グローリッヒ、アニア・モレンダ、リン
                ダ・ココ（エア・ブラバント）、テレサ・フェルドマン、 内田
                聖良、オルガ・ミンク（バルタン・ラボラトリーズ）、 堀内
                奈穂子（AIT）、東海林 慎太郎（AIT）
              </dd>
              <dt>翻訳</dt>
              <dd>池田 哲、ジェイミ・ハンフリーズ、東海林 慎太郎</dd>
              <dt>執筆（英）</dt>
              <dd>ArchDesigner19、Joe、Emm、Umaxbayina?、AFREEN KHAN</dd>

              <dt>執筆（和）</dt>
              <dd>アタン、まみこ、halu、mondschein、Yout</dd>

              <dt>ウェブサイトデザイン・構築</dt>
              <dd>萩原 俊矢</dd>

              <dt>協力</dt>
              <dd>寺田 健人、ブレア・イマニ、バルタン・ラボラトリーズ</dd>

              <dt>企画</dt>
              <dd>NPO法人アーツイニシアティヴトウキョウ[AIT/エイト]</dd>
            </dl>
          </CommonDocProfile>
        </section>
        <section>
          <CommonDocProfile>
            <h4>バルタン・ラボラトリーズ</h4>
            <p>
              バルタン・ラボラトリーズは、オランダのアイントホーフェンに本社を置く世界的電気メーカー、フィリップス社のラボに勤務し、「キッド・バルタン」の名で電子音楽分野の先駆けとして活躍したディック・ラージメイカーの功績にインスパイアされ創設。アート・デザイン・サイエンス・テクノロジーの領域を横断する実験的な試みを行い、導き出される知識やアイディア、洞察で他分野の架け橋となることを目指している。ラボは、個々の協働的思考と団体のネットワークを接続する場として機能し、アートとデザインをプログラムの主軸としながら、私たちが生きるテクノロジーにあふれた社会への影響、確信、誘惑などについても思いを巡らせている。
            </p>
            <p>
              <a href="https://www.baltanlaboratories.org" target={`_blank`}>https://www.baltanlaboratories.org</a>
            </p>

            <h4>アーツイニシアティヴトウキョウ[AIT/エイト]</h4>
            <p>
              2001年、現代アートに興味がある誰もが学び、対話し、思考するプラットフォームづくりを目指して、6名のキュレーターとアート・マネージャーが立ち上げた非営利団体。設立から四半世紀を目前に迎える今、私たちが生きる世界は、はかり知れない危機的な環境問題や人権問題、世界的疫病の流行など、複層的で困難な状況を一人一人が考える時代にいる。AITは、これまでに培った経験と知見を生かし、世界に広がる協働パートナーとともに、芸術文化を通じて持続可能な社会に深く関心を寄せ、歩みを進めます。
            </p>
            <p>
              <a href={`https://www.a-i-t.net/`} target={`_blank`}><img src={ImgAitLogo} alt="AIT Logo" style={{ width: `100px` }}/></a>
            </p>
          </CommonDocProfile>
        </section>
        <section>
          <p>令和2年度 文化庁 アーティスト・イン・レジデンス活動支援事業 <br />
            <a href={`https://www.bunka.go.jp/`} target={`_blank`}><img src={ImgBunkacho} style={{ width: `80px` }} alt={`Bunkacho Logo`} /></a>
          </p>
        </section>
      </CommonDoc>
    </main>
  );
};

export default AboutPage;
